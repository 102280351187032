/*!
 *  - index.js
 * @generated Tue Nov 19 2019 09:28:55 GMT+0900 (東京 (標準時))
 */
'use strict';

/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function ($, DIC) {
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	var slider = function slider() {
		if ($('.index-mainSlide').length) {
			$('.index-mainSlide').slick({
				autoplay: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				loop: true,
				arrows: true,
				prevArrow: '<a class="slick-prev" href="#"></a>',
				nextArrow: '<a class="slick-next" href="#"></a>',
				dots: false
			});
		}
	};
	var setTicker = function setTicker() {
		// const cutTickerText = (_cnt) => {
		// 	$('.index-ticker-item').find('.index-ticker-title-text').each((idx, ele) => {
		// 		var rawText = $(ele).text();
		// 		if(rawText.length > _cnt) {
		// 			$(ele).text(rawText.substring(0, _cnt) + '...');
		// 		}
		// 	});
		// };
		// if($(window).width() >= 1024) {
		// 	cutTickerText(40);
		// } else if($(window).width() < 1024 && $(window).width() >= 768) {
		// 	cutTickerText(30);
		// } else if($(window).width() < 768) {
		// 	cutTickerText(5);
		// }

		var $setElm = $('.js-ticker ul');

		$setElm.slick({
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplaySpeed: 5000,
			loop: true,
			speed: 1000,
			arrows: false,
			dots: false
		});
		setTimeout(function () {
			$('.js-ticker').addClass('is-active');
		}, 100);
	};

	slider();
	if (location.pathname.split('/')[1] === 'cn') {
		setTicker();
	}
})(window.jQuery, window.DIC);